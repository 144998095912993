import { GenericSearchResult } from "./GenericSearchResult"

export class MultipleSearchResults {
    sokId: GenericSearchResult | null
    ean: GenericSearchResult | null
    eanEE: GenericSearchResult | null

    constructor(
        sokId: GenericSearchResult | null,
        ean: GenericSearchResult | null,
        eanEE: GenericSearchResult | null
    ) {
        this.sokId = sokId
        this.ean = ean
        this.eanEE = eanEE
    }

    private resultsAsArray(): (GenericSearchResult | null)[] {
        return [this.sokId, this.ean, this.eanEE]
    }

    hasSessionExpired(): boolean {
        return this.resultsAsArray().some((r) => r?.statusCode === 401)
    }

    getNumberOfResults(): number {
        return this.resultsAsArray().filter((result) => result !== null).length
    }

    getOneResultOrNull(): GenericSearchResult | null {
        const nonNullResults = this.resultsAsArray().filter((r) => r !== null)
        if (nonNullResults.length === 1) {
            return nonNullResults[0]!
        } else {
            return null
        }
    }
}
